<template>
  <div class="page">
    <div class="top-bar_1"></div>
    <!-- 顶栏 -->
    <div class="top-bar_2 w">
      <div class="logo">
        <img src="@/assets/images/logo.png" class="logo" />
      </div>
    </div>

    <!-- 轮播图 -->
    <div class="banner">
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item>
          <img src="@/assets/images/index/banner_1.jpg" style="weight: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 主体介绍 -->
    <div class="main w">
      <div class="title">
        <div class="text">易阁智造</div>
        <div class="short-line"></div>
      </div>

      <div class="floor_1">
        <img src="@/assets/images/index/floor_1_left.jpg" class="left" />
        <img src="@/assets/images/index/floor_1_right.jpg" class="right" />
      </div>

      <div class="floor_2">
        <img src="@/assets/images/index/image_1.jpg" alt="" />
      </div>
    </div>

    <div class="footer_1">
      <img src="@/assets/images/index/image_2.jpg" alt="" />
    </div>

    <div class="footer_2 w">
      <div class="row_1">浙ICP备15005403号-2</div>
      <div class="row_2">
        <span class="text_1">地址：杭州市余杭区联胜路5号大名国际3幢306室</span>
        <span class="text_2">电话：13758279884</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      screenWidth: 0,
      bannerHeight: 0
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  mounted() {
    // 首次加载时,初始化高度
    this.setBannerHeight()
    // 窗口大小发生改变
    window.onresize = () => {
      this.setBannerHeight()
    }
  },

  methods: {
    // 设置轮播图区域高度
    setBannerHeight() {
      this.screenWidth = window.innerWidth
      this.bannerHeight = (3201 / 8192) * this.screenWidth
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .w {
    width: 1200px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .top-bar_1 {
    height: 34px;
    background: #131436;
  }

  .top-bar_2 {
    display: flex;
    align-items: center;
    height: 118px;

    .logo {
      width: 187px;
      height: 53px;
    }
  }

  .banner {
    width: 100%;
  }

  .main {
    .title {
      margin-top: 99px;
      text-align: center;

      .text {
        font-size: 42px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #333333;
        line-height: 59px;
      }

      .short-line {
        width: 60px;
        height: 4px;
        margin: 16px auto 0;
        background: #5098ef;
      }
    }

    .floor_1 {
      display: flex;
      justify-content: space-between;
      margin-top: 103px;
      // background-color: red;

      .left {
        width: 812px;
      }

      .right {
        width: 380px;
      }
    }

    .floor_2 {
      margin-top: 128px;
    }
  }

  .footer_1 {
    margin-top: 73px;
  }

  .footer_2 {
    height: 164px;
    padding-top: 1px;
    background-color: #fff;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    text-align: center;

    .row_1 {
      margin-top: 26px;
    }

    .row_2 {
      margin-top: 17px;

      .text_1 {
        margin-right: 49px;
      }
    }
  }
}
</style>
